import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Element from 'element-ui'
import filters from './filter/index'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/main.scss';
Object.keys(filters).forEach(k => {
  Vue.filter(k, filters[k])
})
Vue.prototype.$validate = function (formName) {
  let a = true
  this.$refs[formName].validate((valid) => {
    if (!valid) {
      a = false
      this.$message.error({
        title: '错误',
        message: '请检查完善输入项并重试',
        duration: 1500
      });
      return
    }
  })
  return a
}
Vue.use(Element);
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.directive('debounce', {//按钮防抖指令
  inserted: function (el, binding) {
    let timer;
    let i = 0
    el.addEventListener("click", () => {
      if (i++ >= 5) {
        Element.Message.warning('操作频繁，5秒后重试')
        i = 0
      }
      if (timer) {
        clearTimeout(timer);
      }
      let callNow = !timer
      timer = setTimeout(() => {
        timer = null
        i = 0
      }, 5000)
      if (callNow) binding.value();
    })
  }
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
