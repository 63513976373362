export default{
    multiplyWithPrecision(arg1, arg2){
      let m = 0;
      let s1 = arg1.toString();
      let s2 = arg2.toString();
      try {
          m += s1.split(".")[1].length;
      } catch (e) {}
      try {
          m += s2.split(".")[1].length;
      } catch (e) {}
  
      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },
    formateDate(oldDate,format='yyyy/MM/dd HH:mm:ss') {
      if(!oldDate){
          return '';
      }
      oldDate = oldDate.replace(/-/g,'/')
      const date = new Date(oldDate)
    const config = {
      yyyy: date.getFullYear(),
      M: date.getMonth() + 1,
      MM: formatNum(date.getMonth() + 1),
      W: date.getDay(),
      WW: formatNum(date.getDay()), 
      d: date.getDate(),
      dd: formatNum(date.getDate()),
      H: date.getHours(),
      HH: formatNum(date.getHours()),
      h: date.getHours() > 12 ? (date.getHours() - 12) : date.getHours(),
      hh: formatNum(date.getHours()) > 12 ? (formatNum(date.getHours()) - 12) : formatNum(date.getHours()),
      m: date.getMinutes(),
      mm: formatNum(date.getMinutes()),
      s: date.getSeconds(),
      ss: formatNum(date.getSeconds()),
      A: date.getHours() < 12 ? 'AM' : 'PM',
      a: date.getHours() < 12 ? 'am' : 'pm'
    }
    const formatConfigs = format.match(/[a-zA-Z]+/g)
    formatConfigs.forEach(item => {
      format = format.replace(item, config[item])
    })
    return format
  },
  getNowDate(format='yyyy/MM/dd HH:mm:ss'){
    let date = new Date();

    const config = {
      yyyy: date.getFullYear(),
      M: date.getMonth() + 1,
      MM: formatNum(date.getMonth() + 1),
      W: date.getDay(),
      WW: formatNum(date.getDay()), 
      d: date.getDate(),
      dd: formatNum(date.getDate()),
      H: date.getHours(),
      HH: formatNum(date.getHours()),
      h: date.getHours() > 12 ? (date.getHours() - 12) : date.getHours(),
      hh: formatNum(date.getHours()) > 12 ? (formatNum(date.getHours()) - 12) : formatNum(date.getHours()),
      m: date.getMinutes(),
      mm: formatNum(date.getMinutes()),
      s: date.getSeconds(),
      ss: formatNum(date.getSeconds()),
      A: date.getHours() < 12 ? 'AM' : 'PM',
      a: date.getHours() < 12 ? 'am' : 'pm'
    }
    const formatConfigs = format.match(/[a-zA-Z]+/g)
    formatConfigs.forEach(item => {
      format = format.replace(item, config[item])
    })
    return format

  },
     
    checkTowDateInMinutes(value1, value2) {//判断两个时间是否在同一分钟内
      let date1 = new Date(value1.replace(/-/g, "/")) 
      let date2 = new Date(value2.replace(/-/g, "/")) 
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() &&
        date1.getHours() === date2.getHours() &&
        date1.getMinutes() === date2.getMinutes()
      );
    },
    address(value){
      if (!value) return ''
      let address = ''
      for (let item of areaList) {
        if (item.id == value) {
          address = item.content
          return address
        }
        if (item.children && item.children.length) {
          for (let item2 of item.children) {
            if (item2.id == value) {
              address = item.content + ' ' + item2.content
              return address
            }
            if (item2.children && item2.children.length) {
              for (let item3 of item2.children) {
                if (item3.id == value) {
                  address = item.content + ' ' + item2.content + ' ' + item3.content
                  return address
                }
              }
            }
          }
        }
      }
    },
    typeName(value){
      return value == 'add' ? '添加' : value == 'edit' ? '编辑' : value == 'show' ? '查看' : ''
    },
    semesterName(value){
      return value == 0? '春季学期':'秋季学期';
    }
}

function formatNum(num) {
  return num < 10 ? `0${num}` : num
}