import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
  },
  {
    path: '/index',
    redirect: '/default',
    component: () => import('@/views/index/index'),
    children: [
      {
        path: '/default',
        component: () => import('@/views/index/default.vue'),
        name: 'default',
      },
      /**     系统设置 */
      {
        path: '/setting/menu', 
        name: 'menu',
        component: () => import('@/views/setting/menu/index.vue'),
      },
      {
        path: '/setting/account',
        name: 'account',
        component: () => import('@/views/setting/account/index.vue'),
      },
      {
        path: '/setting/organization',
        name: 'organization',
        component: () => import('@/views/setting/organization/index.vue'),
      },

      /*******************************基础管理 */
      //品牌管理
      {
        path: '/base/brand',
        name: 'brand',
        component: () => import('@/views/base/brand/index.vue'),
      },
      //计量单位
      {
        path: '/base/unit',
        name: 'unit',
        component: () => import('@/views/base/unit/index.vue'),
      },
      //商品分类
      {
        path: '/base/goodsType',
        name: 'goodsType',
        component: () => import('@/views/base/goodsType/index.vue'),
      },
      //学校类型
      {
        path: '/base/schoolType',
        name: 'schoolType',
        component: () => import('@/views/base/schoolType/index.vue'),
      },
      //默认征订书
      {
        path: '/base/remind',
        name: 'remind',
        component: () => import('@/views/base/remind/index.vue'),
      },

      /*****************************客户管理 */
      //学校管理
      {
        path: '/customer/school',
        name: 'school',
        component: () => import('@/views/customer/school/index.vue'),
      },
      {
        path: '/customer/student',
        name: 'student',
        component: () => import('@/views/customer/student/index.vue'),
      },
      /***************营销管理********************* */
      //商品管理
      {
        path: '/marketing/goods',
        name: 'goods',
        component: () => import('@/views/marketing/goods/index.vue'),
      },
       //商品优惠管理
       {
        path: '/marketing/goodsFavorable',
        name: 'goodsFavorable',
        component: () => import('@/views/marketing/goodsFavorable/index.vue'),
      },
      //组合管理
      {
        path: '/marketing/combination',
        name: 'combination',
        component: () => import('@/views/marketing/combination/index.vue'),
      },
      //套餐管理
      {
        path: '/marketing/package',
        name: 'package',
        component: () => import('@/views/marketing/package/index.vue'),
      },
      //学生信息卡管理
      {
        path: '/marketing/stuCard',
        name: 'stuCard',
        component: () => import('@/views/marketing/card/stuCard/index.vue'),
      },
      //教师信息卡管理
      {
        path: '/marketing/teachCard',
        name: 'teachCard',
        component: () => import('@/views/marketing/card/teachCard/index.vue'),
      },
      /**********************订单管理 */
      //学生订单管理
      {
        path: '/order/stuOrder',
        name: 'stuOrder',
        component: () => import('@/views/order/studentOrder/index.vue'),
      },
      //全部订单管理
      {
        path: '/order/all',
        name: 'allOrder',
        component: () => import('@/views/order/all/index.vue'),
      },
      //教师订单管理
      {
        path: '/order/teacher',
        name: 'teacherOrder',
        component: () => import('@/views/order/teachOrder/index.vue'),
      },
      //订单统计-学校级别
      {
        path: '/order/statistics',
        name: 'statistics',
        component: () => import('@/views/statistics/index.vue'),
      },
      //财务对账单-按时间统计
      {
        path: '/finance-time',
        name: 'financeTime',
        component: () => import('@/views/finance/time/index.vue'),
      },
      //财务对账单-按学期统计
      {
        path: '/finance-semester',
        name: 'financeSemester',
        component: () => import('@/views/finance/semester/index.vue'),
      },
      //批量退款-学生
      {
        path: '/batch-refund',
        name: 'batchRefund',
        component: () => import('@/views/order/batchRefund/index.vue'),
      },
      //退款记录
      {
        path: '/refund-record',
        name: 'refundRecord',
        component: () => import('@/views/order/refundRecord/index.vue'),
      },
      //售后服务-意见反馈
      {
        path: '/service/feedback',
        name: 'feedback',
        component: () => import('@/views/service/feedback/index.vue'),
      },
      //售后服务-自动回复配置
      {
        path: '/service/autoReplyBase',
        name: 'autoReplyBase',
        component: () => import('@/views/service/autoReplyBase/index.vue'),
      },

    ]
  },


];
const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}


const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.beforeEach((to, from, next) => {
  if (to.path === '/login') { //如果是登录页，则跳过验证
    next() //必不可少
    return //以下的代码不执行
  }
  if (!sessionStorage.getItem('x-token') && router.path !== '/') {
    next({
      path: '/login',
    })
  } else next();

});
export default router
